<template>
  <div class="discount-list">
    <router-link
      :to="{name: 'partners-discount', params: {id: $route.params.id, discount: discount.id}}"
      v-for="discount in discounts"
      :key="discount.id"
      class="discount-list__item"
    >
      <div class="discount-list__logo">
        <img v-if="!discount.photo_url" src="@/assets/img/sale-slug.svg" alt="Discount slug">
        <img v-else :src="discount.photo_url" alt="Discount logo">
      </div>
      <div class="discount-list__info">
        <div class="discount-list__name">{{ discount.title }}</div>
        <div
          class="discount-list__type"
          :class="{'discount-list__type--spec': discount.type.id === 2}"
        >
          {{ discount.type.name }} <span v-if="discount.size">{{ discount.size }}%</span>
        </div>
<!--        <p v-if="discount.desc" class="discount-list__desc">{{ discount.desc }}</p>-->
        <p class="discount-list__row">
          <img src="@/assets/img/cal-active.svg" alt="calendar-icon">
          C {{ discount.date_start }} <span v-if="discount.date_end">по {{ discount.date_end }}</span>
        </p>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: ['discounts'],
};
</script>

<style lang="sass">
@import "@/assets/common/index.scss"

.discount-list
  max-height: 950px
  overflow-x: hidden
  overflow-y: auto
  padding-right: 2px
  scrollbar-color: $color-theme #f2f3f8
  scrollbar-width: thin

  &::-webkit-scrollbar
    width: 2px

  &::-webkit-scrollbar-track
    border-radius: 5px
    background-color: #f2f3f8

  &::-webkit-scrollbar-thumb
    border-radius: 5px
    background-color: $color-theme

.discount-list__item
  display: flex
  margin-bottom: 20px
  padding: 20px
  border-radius: 10px
  box-shadow: 0 0 4px rgba(151, 151, 151, 0.25)
  background: #F8FDFF
  text-decoration: none
  transition: background-color 0.3s

  &:hover
    background-color: #EDF7FF

  &:last-child
    margin-bottom: 0

  +max-w($mobile_sm)
    flex-direction: column

.discount-list__logo
  flex-shrink: 0
  overflow: hidden
  border-radius: 50%
  width: 55px
  height: 55px
  margin-right: 20px

  img
    width: 100%
    height: 100%
    object-fit: cover

  +max-w($mobile_sm)
    align-self: center
    width: 100px
    height: 100px
    margin-right: 0
    margin-bottom: 10px

.discount-list__info
  display: flex
  flex-direction: column
  align-items: flex-start

  +max-w($mobile_sm)
    align-items: center

.discount-list__name
  margin-bottom: 15px
  color: $color-text-base
  font-size: 24px
  font-weight: 600

  +max-w($mobile_sm)
    text-align: center

.discount-list__type
  margin-bottom: 15px
  padding: 2px 20px
  border-radius: 50px
  color: #fff
  background-color: $color-theme

.discount-list__type--spec
  background-color: #FF9416

.discount-list__row
  position: relative
  margin-bottom: 10px
  padding-left: 30px
  color: $color-text-base
  font-size: 16px

  img
    position: absolute
    left: 0

  &:last-child
    margin-bottom: 0

  +max-w($mobile_sm)
    align-self: flex-start
</style>